<template>
  <div
    class="detail-panel panel"
    :class="{ 'detail-panel-no-mr': detailBlockNoMr }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Wrap",
  props: {
    detailBlockNoMr: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.detail-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  text-decoration: none !important;
  margin-top: 20px;
  padding: 18px 16px 18px 15px;
  border-radius: 8px;

  &-no-mr {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1080px) {
  .detail-panel {
    padding: 18px 17px 18px 17px;
  }
}

@media only screen and (min-width: 1280px) {
  .detail-panel {
    padding: 24px 20px 30px;
  }
}

@mixin color-mute {
  color: #717171;
}

$color-muted: #717171;

p {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

.empty {
  height: 15px;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.bottom-block {
  align-items: flex-end;
}

.pad-block {
  padding-bottom: 15px;
}

.text {
  &-center {
    text-align: center;
  }

  &-mute {
    @include color-mute();
  }

  &-small {
    font-size: 14px;
    line-height: 16px;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  // &-medium {
  //     font-size: 15px;
  // }
}

.second-title {
  font-size: 17px;
  line-height: 22px;
}

.interval-small {
  margin-bottom: 6px;
}

// .panel-title {
//     font-size: 22px;
// }

h1 {
  font-size: 22px;
}

h3 {
  font-size: 17px;
  line-height: 22px;
}

.panel-planning {
  @include color-mute();
}

.panel-body {
  margin-top: 10px;
}

.panel-info-list {
  display: flex;
  flex-wrap: wrap;
}

.info-list-item {
  @include color-mute();
  display: inline-block;
  margin-left: 30px;

  &:first-child {
    margin-left: 0;
  }
}

.info-list-icon {
  font-size: 30px;
}

.info-list-primary-title {
  font-size: 22px;
}

.info-list-b {
  height: 30px;
}

.b-info {
  &-flex {
    display: flex;
    flex-wrap: wrap;

    .info-item {
      flex-grow: 1;
    }
  }

  &-column {
    columns: auto 2;

    &-item {
      page-break-inside: avoid;
      break-inside: avoid;
      width: 100%;
    }
  }

  &-marg {
    margin-bottom: 20px;
  }
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-75 {
  width: 75%;
}

.info-line {
  display: flex;
  flex-wrap: wrap;
}

.info-block {
  display: block;

  &-pad-md {
    margin: 0 0 15px;
  }
}

.info-item {
  &-pad-xl {
    padding-bottom: 20px;
  }

  &-pad-lg {
    padding-bottom: 15px;
  }

  &-pad-md {
    padding-bottom: 8px;
  }

  &-pad-sm {
    padding-bottom: 5px;
  }

  &-pad-xs {
    padding-bottom: 0;
  }

  &-top {
    &-pad-xl {
      padding-top: 20px;
    }

    &-pad-md {
      padding-top: 8px;
    }
  }
}

.info-checkbox-position {
  .custom-control-label {
    display: inline-block;
  }
}
</style>
