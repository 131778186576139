<template>
  <div class="detail-slider">
    <Slider
      :media="media"
      :view-port="viewPort"
      :video="video"
      :is-new-navigation="true"
      :is-nav="false"
      :btn-round="true"
      :show-desk="false"
      :theme="'detail'"
      height="520px"
      @click="onSliderClick"
    />
    <Gallery
      v-if="viewImage"
      :media="media"
      :video="video"
      :view-image="viewImage"
      :selected-slide="selectedSlide"
    />
  </div>
</template>
<script>
import Slider from "@sutochno/sliderExtended";
import Gallery from "@sutochno/gallery";
import { mapState, mapGetters } from "vuex";
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "DetailSlider",
  components: {
    Slider,
    Gallery,
  },
  props: {
    media: {
      type: Array,
      required: true,
    },
    video: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      viewImage: false,
      selectedSlide: 0,
      observer: null,
    };
  },
  watch: {
    viewImage: {
      handler: function (val) {
        if (val) {
          this.metricaCalling('object_img')
        }
      }
    }
  },
  methods: {
    onSliderClick(event) {
      if (event?.target?.classList?.contains('navigation__img')) {
        this.metricaCalling('object_img_next')
      }
    },
    metricaCalling(screenBlock = 'object_img') {
      sutochnoMetrika.detail('click', screenBlock, {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
    initNativeListeners() {
      const btns = document.querySelectorAll('.carousel__next, .carousel__prev');
      btns.forEach((btn) => {
        btn.addEventListener('click', () => {
          this.metricaCalling('object_img_next');
        })
      })

      const config = {
        childList: true,
        subtree: true,
      };

      const target = document.querySelector('.detail-slider')

      const callback = (mutationList) => {
        mutationList.forEach((mutation) => {
          if(mutation.type === 'childList' && mutation.addedNodes.length) {
            mutation.addedNodes.forEach((node) => {
              if(node instanceof Element && node.classList.contains('gallery')) {
                const btns = node.querySelectorAll('.carousel__next, .carousel__prev');
                btns.forEach((btn) => {
                  btn.addEventListener('click', () => {
                    this.metricaCalling('object_img_next');
                  })
                })
              }
            })
          }
        })
      }

      this.observer = new MutationObserver(callback);
      // TODO - на объекте 1435857 происходит ошибка из-за target
      if (target) {
        this.observer.observe(target, config);
      }
    }
  },
  computed: {
    ...mapState("user", ["viewPort"]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
  },
  mounted() {
    this.initNativeListeners();
  },
  unmounted() {
    this.observer.disconnect();
  }
};
</script>
<style lang="scss" scoped>
.detail-slider {
  overflow: hidden;
}
</style>
