<i18n lang="json">
{
	"ru": {
    "lastSeen": "был на сайте ",
    "weeks": "менее недели | более недели | более недели | более недели",
    "days": "менее дня | 1 день | {n} дня | {n} дней",
    "hours": "менее часа | 1 час | {n} часа | {n} часов",
    "minutes": "менее минуты | 1 минуту | {n} минуты | {n} минут",
    "answerMinutes": "менее минуты | 1 минута | {n} минуты | {n} минут",
    "back": "назад",
    "requests": "Обрабатывает запросы ",
    "showProcessRequestsAlwaysLabelAbility": "всегда",
    "showProcessRequestsSometimesLabelAbility": "иногда",
    "showProcessRequestsRarelyLabelAbility": "редко",
    "showProcessRequestsOftenLabelAbility": "часто",
    "showProcessRequestsNotOftenLabelAbility": "нечасто",
    "nowOnSite": "сейчас на сайте",
    "answerTimeMoreTwelve": "более 12 ч.",
    "rankOwner": "Арендодатель",
    "rankSuper": "Суперхозяин",
    "rankNewby": "Новичок",
    "answerTime": "Среднее время отклика",
    "speakLangs": "Общается на языках",
    "participant": "участник с",
    "hintProfi": "Успешно прошёл тестирование на знание правил бронирования Суточно.ру",
    "goldPartnerLabel": "Золотой партнер",
    "silverPartnerLabel": "Серебряный партнер",
    "superhostText": "один из лучших на Суточно.ру",
    "superhostText2": "Имеет много успешных бронирований и хороших отзывов.",
    "profiText": "Это знаток Суточно.ру. Хорошо знает, как оформлять бронирования и принимать гостей.",
    "tenYearsText": "Партнёр Суточно.ру уже 11 лет",
    "host": "Хозяин жилья",
    "gold-partner": "Золотой партнёр Суточно.ру",
    "silver-partner": "Серебряный партнёр Суточно.ру"
	},
	"en": {
    "lastSeen": "last seen ",
    "weeks": "less than a week ago | over a week ago | over a week ago | more than a week ago",
    "days": "days ago | 1 day ago | {n} days ago | {n} days ago",
    "hours": "less than an hour ago | 1 hour ago | {n} hours ago | {n} hours ago",
    "minutes": "less than a minute ago | 1 minute ago | {n} minutes ago | {n} minutes ago",
    "answerMinutes": "less than a minute | 1 minute | {n} minutes | {n} minutes",
    "back": "back",
    "requests": "Processes requests:",
    "showProcessRequestsAlwaysLabelAbility": "always",
    "showProcessRequestsSometimesLabelAbility": "sometimes",
    "showProcessRequestsRarelyLabelAbility": "rarely",
    "showProcessRequestsOftenLabelAbility": "often",
    "showProcessRequestsNotOftenLabelAbility": "not often",
    "reqAlways": "always",
    "reqSometimes": "sometimes",
    "reqRarely": "rarely",
    "reqOften": "often",
    "nowOnSite": "online",
    "answerTimeMoreTwelve": "more than 12 hours",
    "rankOwner": "Host",
    "rankSuper": "Super-host",
    "rankNewby": "Newbie",
    "answerTime": "Response time",
    "speakLangs": "Communicates in languages",
    "participant": "participant since",
    "hintProfi": "Having successfully passed the test for knowledge of the rules of reservation Sutochno.ru",
    "goldPartnerLabel": "Gold partner",
    "silverPartnerLabel": "Silver partner",
    "superhostText": "one of the best on Sutochno.ru",
    "superhostText2": "Has many successful bookings and good reviews.",
    "profiText": "This is an expert on Sutochno.ru. Knows well how to make reservations and receive guests.",
    "tenYearsText": "Partner Sutochno.ru for 11 years",
    "host": "Host",
    "gold-partner": "Golden partner Sutochno.ru",
    "silver-partner": "Silver partner Sutochno.ru"
	}
}
</i18n>
<template>
  <div class="card-owner tmp-font--medium">
    <div>
      <div class="avatar"><img :src="srcAvatar" alt="" /></div>
      <div class="name tmp-font--big_bold">
        {{ ownerName }}
      </div>
      <div class="label">
        <span
          v-if="findPermissions('showSuperhostLabelAbility')"
          class="label--superhost"
          >{{ t("rankSuper") }}</span
        >
        <span
          v-if="getPartnerStatus"
          class="hint-popup"
          :data-hint="t(`${getPartnerStatus}`)"
        >
          <span
            class="icon-app-partner partner-icon"
            :class="getPartnerStatus"
          ></span>
        </span>
        <span class="tmp-font--medium_gray">
          {{ t("participant") }}&nbsp;{{ dateAdd }}
        </span>
      </div>
    </div>
    <div v-if="wasOnSite" class="was-on-site tmp-font--medium_gray">
      {{ wasOnSite }}
    </div>
    <div class="permissons">
      <div v-if="findPermissions('showSuperhostLabelAbility')">
        <strong>{{ t("rankSuper") }} — {{ t("superhostText") }}</strong
        ><br />{{ t("superhostText2") }}
      </div>
      <template v-if="!findPermissions('showSuperhostLabelAbility')">
        <div v-if="findPermissions('showProLabelAbility')" class="graduate-cap">
          <span class="icon-app-graduate-cap"></span>
          <span class="tmp-font--medium_gray">{{ t("profiText") }}</span>
        </div>
      </template>
    </div>
    <div class="clock">
      <p v-if="processRequest">
        <span class="icon-app-messenger"></span>{{ t("requests") }}
        {{ t(processRequest) }}
      </p>
      <p v-if="answerTimeVal">
        <span class="icon-app-clock-info"></span>{{ t("answerTime") }} —
        {{ answerTimeVal }}
      </p>
    </div>
    <div
      v-if="
        owner.speak_languages &&
        owner.speak_languages.length &&
        owner.speak_languages.length > 0
      "
    >
      {{ t("speakLangs") }} —
      <span v-for="(language, i) in owner.speak_languages" :key="i">{{
        i > 0 ? `, ${languages[language.name]}` : languages[language.name]
      }}</span>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { formatSafari } from "@/utils/format";
import languages from "./languages.json";
import { useI18n } from 'vue-i18n';

export default {
  name: "Cart",
  props: {
    owner: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isTime: null,
      ownerTime: null,
      newObj: Object,
      languages,
    };
  },
  computed: {
    getPartnerStatus(){
      if (this.findPermissions("showGoldPartnerLabelAbility")) {
        return "gold-partner";
      } else if (this.findPermissions("showSilverPartnerLabelAbility")) {
        return "silver-partner";
      } else{
        return null;
      }
    },
    ownerName() {
      const ownerCompany = String(this.owner?.company);
      if(ownerCompany) {
        return _.unescape(ownerCompany.replace('&amp;amp;', '&amp;'));
      }
      if (
        this.owner.name === "Пользователь" ||
        this.owner.name === "user" ||
        !this.owner.name ||
        String(this.owner.name).trim().length <= 2
      ) {
        return this.t("host");
      } else {
        return this.owner.name;
      }
    },
    answerTimeVal() {
      if (this.answerTime.name == "hours") {
        return this.t(this.answerTime.name, this.answerTime.value);
      } else if (this.answerTime.name == "answerMinutes") {
        return this.t(this.answerTime.name, this.answerTime.value);
      } else {
        return this.t("answerTimeMoreTwelve");
      }
    },
    wasOnSite() {
      if (this.dateText.nowOnSite) {
        return this.t("online", this.dateText.ownerDate);
      } else if (this.dateText.isDate) {
        return this.dateText.text;
      } else {
        return "";
      }
    },
    dateAdd() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
      }).format(new Date(formatSafari(String(this.owner.date_add))));
    },
    processRequest() {
      const arrPermissons = [
        "showProcessRequestsSometimesLabelAbility",
        "showProcessRequestsRarelyLabelAbility",
        "showProcessRequestsOftenLabelAbility",
        "showProcessRequestsAlwaysLabelAbility",
        "showProcessRequestsNotOftenLabelAbility",
      ];

      let value = arrPermissons.filter((item) => {
        if (this.owner.permissions.includes(item)) return item;
      });

      return value.length > 0 ? value[0] : null;
    },
    dateText() {
      /**
       *
       * Поиск и вывод текста в карточку арендодателя
       *
       * Примеры:
       *
       *  - был на сайте: 4 часа назад
       *  - был на сайте: более недели назад
       *
       * @module Detail
       *
       * @computed
       *
       */

      let dateNow = new Date(); /* Сегодняшняя дата */
      let dateOwner = new Date(
        formatSafari(String(this.owner.date_last_login))
      ); /* Дата последнего входа в приложение */

      let dateResult = new Date(
        dateNow - dateOwner
      ); /* Подсчет времени отсутвия в приложении */

      /* Посчет даты в различные единицы времени */
      const milliseconds = dateResult;
      const seconds = milliseconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      const weeks = days / 7;

      let ownerDate; /* ownerDate - отсутвие по времени в приложении */
      let isDate; /* isDate - единицы измерения */

      /**
       * Вычисление формата в котором будет отображаться
       *
       * Пример:
       *
       * Если owner был online 24 часа назад
       *
       * в минутах: это больше 5 минут и больше 60 минут
       * в часах: 24 часа не меньше 24 часов
       * результатом будет: 1 день
       */

      if (minutes < 5) {
        /* Если меньше 5 минут назад был в приложении то owner online */
        ownerDate = minutes;
        isDate = "online";
      } else if (minutes < 60) {
        ownerDate = minutes;
        isDate = "minutes";
      } else if (hours < 24) {
        ownerDate = hours;
        isDate = "hours";
      } else if (days < 7) {
        ownerDate = days;
        isDate = "days";
      } else {
        ownerDate = weeks;
        isDate = "weeks";
      }

      /* Возвращение результата в нужном формате */

      ownerDate = Math.round(ownerDate);
      if (isDate == "online") {
        /* Пример вывода: 'сейчас на сайте' */
        return {
          isDate,
          ownerDate,
          text: `${this.t("nowOnSite")}`,
        };
      } else {
        /* Пример вывода: 'был на сайте: более недели назад' */
        return {
          isDate,
          ownerDate,
          text: `${this.t("lastSeen")} ${this.t(
            isDate,
            ownerDate
          )} ${this.t("back")}`,
        };
      }
    },
    answerTime() {
      let dateOwner = this.owner.answer_time;
      let minutes = this.owner.answer_time / 60;
      let hours = this.owner.answer_time / 60 / 60;
      if (hours < 12 && hours >= 1) {
        hours = Math.round(hours);
        return {
          value: hours,
          name: "hours",
        };
      } else if (minutes < 60 && minutes >= 0) {
        minutes = Math.round(minutes);
        return {
          value: minutes,
          name: "answerMinutes",
        };
      } else {
        return false;
      }
    },
    srcAvatar() {
      return this.owner.avatar.replace("/small/", "/medium/");
    },
  },
  methods: {
    findPermissions(elementPerm) {
      let permItem;
      let permissions = this.owner.permissions.reduce(function (
        acc,
        currentIndex
      ) {
        if (currentIndex == elementPerm) {
          permItem = currentIndex;
        }
        return permItem;
      },
      0);
      if (elementPerm == "showSuperhostLabelAbility") {
        this.$emit("update:modelValue", permissions);
      }
      return permissions;
    },
  },
};
</script>

<style lang="scss" scoped>
.hint-popup {
  &[data-hint]::after {
    transform: translate(-70%, -100%);
  }
}
.card-owner {
  display: grid;
  grid-template-columns: 1fr 300px;
  row-gap: 28px;

  > div {
    &:nth-child(1) {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-template-areas:
        "avatar name"
        "avatar label";

      .avatar {
        grid-area: avatar;

        img {
          width: 60px;
          border-radius: 50px;
        }
      }

      .name {
        grid-area: name;
        align-self: center;
      }

      .label {
        grid-area: label;
        @media only screen and (max-width: 1024px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          > span {
            display: inline-block;
            margin-top: 4px;
          }
        }

        &--superhost {
          color: #fff;
          font-size: 9px;
          background: #ffa900;
          padding: 3px 10px 4px;
          border-radius: 9px;
          margin-right: 15px;
        }
      }
    }

    &:nth-child(even) {
      justify-self: end;
    }

    &.permissons {
      white-space: pre-line;
      .graduate-cap {
        display: flex;
        align-items: center;
        .icon-app-graduate-cap {
          color: #444;
          margin-right: 15px;
          font-size: 18px;
        }
      }
    }

    &.clock {
      .icon-app-messenger,
      .icon-app-clock-info {
        color: #f51449;
        margin-right: 10px;
        font-size: 16px;
        vertical-align: bottom;
      }

      p {
        margin: 0 0 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.rank-ten-years {
  position: relative;
  margin-right: 10px;
  &.hint-popup[data-hint] {
    &::before,
    &::after {
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &::before {
      top: -19px;
    }
    &::after {
      top: -8px;
    }
    &:hover {
      &::before,
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.was-on-site {
  padding-top: 11px;
}

.partner-icon{
  vertical-align: middle;
  display: inline-block;
  transform: translateX(-4px);
}
.gold-partner{
  color: #f1c260;
}
.silver-partner{
  color: #999999;
}
</style>
