<i18n lang="json">
{
  "ru": {
    "landlord": "хозяин жилья",
    "user": "Пользователь",
    "commentMore": "Показать ещё"
  },
  "en": {
    "landlord": "landlord",
    "user": "User",
    "commentMore": "Show more"
  }
}
</i18n>

<template>
  <div class="feedback">
    <div class="feedback__top">
      <div class="feedback__img-wrapper">
        <!-- <img
          v-if="feedbackAuthor.avatar?.filename?.value && feedbackAuthor.avatar?.full_path?.value"
          class="feedback__img"
          alt="avatar"
          :src="`//static.sutochno.ru/${feedbackAuthor.avatar.full_path.value}/small/${feedbackAuthor.avatar.filename.value}`"
        /> -->
        <img
          class="feedback__img"
          alt="default-avatar"
          src="~/assets/img/default-avatar-new.svg"
        />
      </div>
      <div class="feedback__content">
        <div
          v-if="feedbackAuthor.firstName"
          class="feedback__title"
        >
          {{ feedbackAuthor.firstName }}<span class="feedback__owner">, {{ t("landlord") }}</span>
        </div>
        <div v-else class="feedback__title">{{ t("user") }}</div>
      </div>
    </div>
    <p class="feedback__content-text">
      {{ getFeedbackText }}
    </p>
    <span
      v-if="!showFullFeedbackText && feedbackText.length > maxCommentLength"
      class="feedback__more tmp-font--small"
      @click="showFullFeedbackText = true"
    >
      {{ t("commentMore") }}
    </span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  feedbackAuthor: {
    type: Object,
    default: () => {
      return {
        firstName: '',
        avatar: null
      }
    }
  },
  feedbackText: {
    type: String,
    default: () => '',
  },
});

const { t } = useI18n();

const maxCommentLength = 250;
const showFullFeedbackText = ref(false);

const getFeedbackText = computed(() => {
  if (props.feedbackText.length <= maxCommentLength || showFullFeedbackText.value) {
    return props.feedbackText;
  }

  return props.feedbackText.substring(0, maxCommentLength).trim() + "...";
})

</script>

<style lang="scss" scoped>
.feedback {
  padding-top: 15px;
  position: relative;
  &__title {
    font-weight: bold;
    font-size: 14px;
  }
  &__owner {
    color: #717171;
  }
  &__top {
    display: flex;
    align-items: center;
  }
  &__img {
    width: 100%;
    height: 100%;
    &-wrapper {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  &__content {
    margin-left: 15px;
    flex-grow: 1;

    &-text {
      margin-top: 15px;
      line-height: 20px;
      white-space: pre-line;
    }
    &-date {
      color: #a6a6a6;
      font-size: 14px;
      line-height: 16px;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }
  &__more {
    margin-top: 5px;
    color: #2d6cb4;
    cursor: pointer;
  }
}
</style>