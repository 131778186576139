<i18n lang="json">
{
	"ru": {
    "reviewStr": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "viewMore": "Показать ещё {n} отзывов | Показать ещё {n} отзыв | Показать ещё {n} отзыва | Показать ещё {n} отзывов",
    "externalRating": "Остальные отзывы были размещены на зарубежных сервисах, ушедших из России. Мы не публикуем их целиком, т.к. гости не давали явного согласия. Возможно, эти отзывы появятся позже."
	},
	"en": {
    "reviewStr": "reviews | {n} review | {n} reviews | {n} reviews",
    "viewMore": "Show {n} more reviews | Show {n} more review | Show {n} more reviews | Show {n} more reviews",
    "externalRating": "The rest of the reviews were posted on foreign services that left Russia. We do not publish them in full, because. the guests did not give explicit consent. Perhaps these reviews will appear later."
	}
}
</i18n>
<template>
  <div v-if="commentsData" class="reviews__comments" ref="reviews">
    <CommentsSorter :sort-options="sorterOptions" @sort="doSort" v-if="commentsData.commentsCount > 5"/>
    <h2 class="comments__title" v-if="countReviewsText === 1 && maxRatingExternal <= 0">
      {{ t("reviewStr", countReviewsText) }}
    </h2>
    <div class="comments__block">
      <Comment
        v-for="comment in comments"
        :key="comment.id"
        :ref="`comment-${comment.id}`"
        :comment="comment"
        :is-hotel="isHotel"
        :class="{ 'select-review': comment.id == selectMessage }"
      />
    </div>
    <div
      v-if="isNext"
      class="comments__button"
      :class="{ fetchComments: fetchingFromSorter }"
    >
      <div class="comments__item">
        <div class="comments__left"></div>
        <div class="comments__content">
          <button
            class="button button_size_md button-min-width-210"
            :class="{ 'loading loading_primary': load, 'noBorders': fetchingFromSorter }"
            @click="!load ? init(false) : null"
            :disabled="load"
          >
            <span v-if="!load">{{ t("viewMore", { n: countBtn }) }}</span>
          </button>
        </div>
      </div>
    </div>
    <div
    v-if="countReviewsText > commentsData.commentsCount && commentsData.commentsCount > 1"
      class="comments__text"
    >
      {{ t("externalRating") }}
    </div>
  </div>
</template>
<script>
import Comment from "./Comment";
import { Objects } from "@/components/api/";
import { mapGetters, mapMutations } from "vuex";
import { Detail } from "@/utils/gtm/";
import CommentsSorter from '@/components/base/BaseComments/CommentsSorter';
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "BaseComments",
  components: {
    CommentsSorter,
    Comment,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
    maxRatingExternal: {
      type: Number,
      default: 0
    },
    countReviewsExternal: {
      type: Number,
      default: 0
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      offset: 0,
      limit: 0,
      commentsMore: [],
      commentsData: null,
      load: false,
      comments: [],
      selectMessage: null,
      isCountNext: false,
      sorterOptions: [
        { title: 'по умолчанию', value: 'default' },
        { title: 'сначала новые', value: 'new' },
        { title: 'сначала с высокой оценкой', value: 'high' },
        { title: 'сначала с низкой оценкой', value: 'low' },
      ],
      commentsSort: 'default',
      fetchingFromSorter: false,
      firstLoad: true
    };
  },
  watch: {
    commentsSort: {
      handler() {
        this.comments = []
        this.init(true)
      },
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getHotelReviews"]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
    ...mapMutations("detail", ["setLoadingComments"]),
    countBtn() {
      return this.limit > this.commentsData.commentsCount - this.offset
        ? this.commentsData.commentsCount - this.offset
        : this.limit;
    },
    isNext () {
      return this.comments.length < this.commentsData.commentsCount && this.isCountNext;
    },
    countReviews() {
      if (this.isHotel) {
        return this.getHotelReviews?.count || 0;
      } else {
        return this.commentsData?.commentsCount || 0;
      }
    },
    countReviewsText() {
      return this.countReviews + this.countReviewsExternal
    }
  },
  mounted() {
    if (this.$route.query.message_id) {
      this.selectMessage = this.$route.query.message_id;
    }
    this.limit = 5;
    this.init();
  },
  methods: {
    doSort(e){
      this.commentsSort = e;
      Detail.gaSortReviews(this.commentsSort);
    },
    async init(val) {
      this.fetchingFromSorter = val
      if(val) {
        this.offset = 0
        this.limit = 5;
      }
      if(!val && !this.firstLoad){
        Detail.gaClickMoreReviews("desktop", this.getObjectData?.id);

        sutochnoMetrika.search('click', 'object_reviews_more', {
          ...this.commonParamsForMetrika,
          page: "object",
        })
      }
      
      this.load = true;
      const isHotel = this.isHotel ? 1 : 0;
      const sort = this.commentsSort === 'default' ? '' : this.commentsSort
      await Objects.getObjectReviewsMore(
        this.getObjectData?.hotel?.id ? this.getObjectData.hotel.id : this.id,
        Number(this.limit),
        this.offset,
        isHotel,
        sort
      )
        .then((response) => {
          this.load = false;
          const data = response.data.data;
          this.isCountNext = data.comments.length >= this.limit;
          this.commentsData = data;
          // this.commentsData.comments = Object.values(
          //   this.commentsData.comments
          // );
          // this.commentsData.comments.reverse();
          this.comments = [...this.comments, ...this.commentsData.comments];
          this.fetchingFromSorter = false;
          this.firstLoad = false;
          this.setLoadingComments;
          // if (this.isHotel) {
          //   const HOTEL_CATEGORIES = this.$store.getters[
          //     "hotel/hotelObjectsCategory"
          //   ];
          //   this.comments = this.comments.map((c) => {
          //     c.hotelObjectCategory = HOTEL_CATEGORIES.find((element) =>
          //       element.data.map((o) => o.id).includes(String(c.object_id))
          //     );
          //     return c;
          //   });
          // }

          if (this.selectMessage) {
            setTimeout(() => {
              if (this.$refs.reviews.querySelector(".select-review")) {
                window.scrollTo(
                  0,
                  (this.$refs.reviews
                    .querySelector(".select-review")
                    .getBoundingClientRect().top) - 50
                );
              }
            }, 200);
          }

        })
        .catch((error) => {
          console.warn("Ajax error: Objects.getObjectReviewsMore, ", error);
        });
      if (this.offset == 0) this.offset += this.limit;
      else this.offset += 5;
      if (this.isHotel) this.limit = 5;
    },
  },
};
</script>

<style lang="scss" scoped>
.reviews__comments {
  background: #fff;
  padding: 20px;

  .comments {
    &__title {
      font-size: 22px;
      line-height: 28px;
    }
    &__block {
      padding-top: 20px;
    }
    &__text {
      margin-top: 30px;
      color: #7f7f7f;
    }
  }
}
.button-min-width-210{
  min-width: 210px;
}

.fetchComments {
  display: flex;
  justify-content: center;
  border: none !important;
}

.noBorders {
  border: none !important;
}
</style>
