<template>
  <div class="list-city" :class="typeObject">
    <router-link
      v-if="selected !== -1"
      :to="{
        name: 'Search',
        query: {
          term: city[selected]['title'],
          id: city[selected]['id'],
          type: typeLocation,
        },
      }"
      @click.stop.prevent="
        handleClick(city[selected]['title'], city[selected]['id'], typeLocation)
      "
    >
      {{ city[selected]["title"] }}
    </router-link>
    <div v-click-outside="hide" class="list" v-if="selected !== -1">
      <div class="btn-show-list" @click="visibility = !visibility"></div>
      <div
        v-if="visibility"
        :class="[typeLocation, { one: city.length == 1 }]"
        class="modal-list"
      >
        <div class="modal-list--wrapper">
          <div class="modal-list--inner">
            <router-link
              v-for="(item, index) in city"
              :key="index"
              :class="{ active: selected == index }"
              :to="{
                name: 'Search',
                query: {
                  term: item.title,
                  id: item.id,
                  type: typeLocation,
                },
              }"
              @click.stop.prevent="
                handleClick(item.title, item.id, typeLocation)
              "
            >
              {{ item.title }}
              <span class="count">{{ item.cnt }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isNavigationFailure } from "vue-router";

export default {
  name: "ListCity",
  props: {
    city: {
      type: Array,
      default: [],
    },
    selected: {
      type: Number,
      default: null,
    },
    typeLocation: {
      type: String,
      default: "",
    },
    typeObject: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visibility: false,
    };
  },
  computed: {
    ...mapGetters("search", ["getQuery"]),
  },
  methods: {
    hide() {
      this.visibility = false;
    },
    handleClick(term, id, type) {
      const query = {...this.getQuery.query}
      delete query["NE.lat"];
      delete query["NE.lng"];
      delete query["SW.lat"];
      delete query["SW.lng"];
      query.id = id;
      query.term = term;
      query.type = type;
      this.$router
        .push({
          name: "Search",
          query: query,
        })
        .then((failure) => {
          if (isNavigationFailure(failure)) {
            setTimeout(() => {
              this.$router.push({
                name: failure.to.name,
                query: failure.to.query,
              })
            }, 500)
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.list-city {
  a {
    cursor: pointer;
    color: #444444;
    text-decoration: none;
    &:hover {
      color: #444444;
    }
  }
  &.hotel_id {
    a {
      color: #717171;
      &:hover {
        color: #717171;
      }
    }
  }
}
.list {
  display: inline-block;
  width: 14px;
  height: 12px;
  position: relative;
  top: 2px;
  .btn-show-list {
    cursor: pointer;
    width: 14px;
    height: 12px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 4px;
    &:after,
    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 1px;
      background: #525252;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 5px;
    }
    &:after {
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
      left: 4px;
    }
  }
  .modal-list {
    width: 330px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 16px;
    margin-left: -26px;
    z-index: 2000;
    &.region {
      width: 560px;
      &.one {
        width: 290px;
        .modal-list--inner {
          a {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
    &.city {
      width: 380px;
    }
    &:after {
      content: "";
      position: absolute;
      top: -10px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 10px solid #fff;
    }
    &:before {
      content: "";
      position: absolute;
      top: -11px;
      left: 19px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #d1d1d1;
    }
    &--wrapper {
      padding: 15px 10px;
      max-height: 500px;
      overflow: auto;
    }
    &--inner {
      columns: auto 2;
      flex-wrap: wrap;
      height: 100%;
      a {
        cursor: pointer;
        display: block;
        font-size: 14px;
        color: #444444;
        text-decoration: none;
        padding: 3px 10px;
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
        .count {
          font-size: 13px;
          color: #717171;
        }
        &:hover,
        &.active {
          background-color: #f6f5f3;
          color: #f51449;
          .count {
            color: #717171;
          }
        }
        &.active {
          color: #444;
          font-weight: bold;
          .count {
            font-weight: normal;
          }
          &:hover {
            color: #f51449;
          }
        }
      }
    }
  }
}
</style>
