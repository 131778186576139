<i18n lang="json">
{
	"ru": {
    "landlord": "хозяин жилья",
    "reviewStr": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "viewMore": "+ ещё",
    "user": "Пользователь",
    "dignity": "Достоинства",
    "limitations": "Недостатки",
    "commentMore": "Показать ещё",
    "date": {
      "01": "Январь",
      "02": "Февраль",
      "03": "Март",
      "04": "Апрель",
      "05": "Май",
      "06": "Июнь",
      "07": "Июль",
      "08": "Август",
      "09": "Сентябрь",
      "10": "Октябрь",
      "11": "Ноябрь",
      "12": "Декабрь"
    },
    "addingTime": {
      "0": "сегодня",
      "1": "вчера",
      "2": "позавчера",
      "3": "3 дня назад",
      "4": "4 дня назад",
      "5": "5 дней назад",
      "6": "6 дней назад",
      "7": "неделю назад",
      "8": "2 недели назад",
      "9": "3 недели назад",
      "10": "месяц назад"
    },
    "ratingText": {
      "0": "не понравилось",
      "1": "терпимо",
      "2": "удовлетворительно",
      "3": "неплохо",
      "4": "хорошо",
      "5": "великолепно"
    },
    "averageRating": "Оценка проживания: ",
    "usefull": "Полезно +",
    "useless": "Бесполезно -",
    "lengthOfStay": "{n} суток | {n} сутки | {n} суток | {n} суток",
    "guests": "{n} гостей | {n} гость | {n} гостя | {n} гостей",
    "children": "с детьми",
    "child": "с ребенком"
	},
	"en": {
    "landlord": "landlord",
    "reviewStr": "reviews | {n} review | {n} reviews | {n} reviews",
    "viewMore": "+ more",
    "user": "User",
    "dignity": "Dignity",
    "limitations": "Limitations",
    "commentMore": "Show more",
    "date": {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    },
    "addingTime": {
      "0": "today",
      "1": "yesterday",
      "2": "day before yesterday",
      "3": "3 days ago",
      "4": "4 days ago",
      "5": "5 days ago",
      "6": "6 days ago",
      "7": "a week ago",
      "8": "2 weeks ago",
      "9": "3 weeks ago",
      "10": "month ago"
    },
    "ratingText": {
      "0": "did not like",
      "1": "be patient",
      "2": "satisfactory",
      "3": "not bad",
      "4": "good",
      "5": "great"
    },
    "averageRating": "Accommodation rating: ",
    "usefull": "Usefull +",
    "useless": "useless -",
    "lengthOfStay": "{n} days | {n} day | {n} days | {n} days",
    "guests": "{n} guests | {n} guest | {n} guests | {n} guests",
    "children": "with kids",
    "child": "with child"
	}
}
</i18n>
<template>
  <div :ref="`${comment.id}`" class="comments__item">
    <div class="comments__left">
      <img
        v-if="userAvatar"
        class="comments__img"
        :src="userAvatar"
      />
      <img
        v-else
        class="comments__img--default"
        src="~/assets/img/default-avatar-new.svg"
      />
    </div>
    <div class="comments__content">
      <div v-if="username" class="content__title">
        {{ username }}
        <CommentRating :rating="getRatingForCommentRating" />
      </div>
      <div v-else class="content__title">
        {{ t("user") }}
        <CommentRating :rating="getRatingForCommentRating" />
      </div>
      <div class="comments__flex">
        <div class="comments__booking-desc tmp-font--medium">
          <template v-if="dateStart">
            <span class="icon-app-calendar1 comments__icon"></span>
            <span class="comments__date-of-stay">{{ dateStart }},&nbsp;</span>
          </template>
          <!-- ждем когда бекенд доделает -->
          <template v-if="comment.days">
            <span class="comments__days">{{
              t("lengthOfStay", comment.days)
            }}</span>
            <span class="icon-app-user-3 comments__icon" v-if="guests"></span>
            <span class="comments__guests">{{ guests }}</span>
          </template>
        </div>
        <span
          v-if="getAddingTime"
          class="content__comment-date tmp-font--medium"
        >
          {{ getAddingTime }}
        </span>
      </div>

      <div class="content__comment">
        <div
          v-if="advantageText && advantageText !== 'undefined'"
          class="content__comment-advantage"
        >
          <span
            class="content__comment-like content__comment-icon icon-app-like"
          ></span>
          <p class="content__comment-text">
            {{ showAdvantageText }}
          </p>
          <span
            v-if="!isAllAdvantageText"
            class="content__comment-more tmp-font--small"
            @click="showAllAdvantageText = true"
          >
            {{ t("commentMore") }}
          </span>
        </div>
        <div
          v-if="disadvantageText && disadvantageText !== 'undefined'"
          class="content__comment-disadvantage"
        >
          <span
            class="content__comment-dislike content__comment-icon icon-app-dislike"
          ></span>
          <p class="content__comment-text">
            {{ showDisadvantageText }}
          </p>
          <span
            v-if="!isAllDisadvantageText"
            class="content__comment-more tmp-font--small"
            @click="showAllDisadvantageText = true"
          >
            {{ t("commentMore") }}
          </span>
        </div>
        <div
          v-if="
            (!advantageText && !disadvantageText) ||
            (advantageText === 'undefined' &&
              disadvantageText === 'undefined') ||
            (!advantageText && disadvantageText === 'undefined') ||
            (advantageText === 'undefined' && !disadvantageText)
          "
          class="content__commen-rating"
        >
          {{ t("averageRating") }}{{ getRatingText }}
        </div>
        <div
          v-if="comment.data.media && comment.data.media.length != 0"
          class="content__comment-slider"
        >
          <template v-if="comment.data.media.length > 4">
            <Carousel v-bind="settings">
              <Slide
                v-for="(item, index) in comment.data.media"
                :key="index"
                :index="index"
              >
                <img
                  class="img"
                  :src="`//static.sutochno.ru${item.properties.full_path.value}medium/${item.properties.filename.value}`"
                  alt
                  @click="selectSlide(index)"
                />
              </Slide>
            </Carousel>
          </template>
          <div v-else class="content__comment-img-wrap">
            <div
              v-for="(item, index) in comment.data.media"
              :key="index"
              :index="index"
              class="content__comment-img"
              >
              <img
                class="img"
                :src="`//static.sutochno.ru${item.properties.full_path.value}small/${item.properties.filename.value}`"
                alt
                @click="selectSlide(index)"
              />
            </div>
          </div>
          <Gallery
            v-if="viewImage"
            :media="comment.data.media"
            :view-image="viewImage"
            :alt="false"
            :selected-slide="selectedSlide"
          />
        </div>
      </div>
      <span v-if="roomTitle" class="content__category tmp-font--small">
        {{ roomTitle }}
      </span>
      <div v-if="comment.responses && comment.responses != 0">
        <Feedback
          v-for="response in comment.responses"
          :key="response.id + '__feedback'"
          :feedback-author="{
            firstName: response.user?.data?.properties?.first_name?.value || '',
            avatar: response.user?.data?.media?.[0]?.properties
          }"
          :feedback-text="String(response.data.properties.message_text.value)"
        />
      </div>
      <!--       <div class="content__comment-benefit tmp-font--small">
        <span
          class="content__comment-useless"
          :class="{ 'tmp-font--small_bold': benefit === 'useless' }"
          @click="clickUseless()"
          >{{ t("useless") }}</span
        >
        <span
          class="content__comment-usefull"
          :class="{ 'tmp-font--small_bold': benefit === 'usefull' }"
          @click="clickUsefull()"
          >{{ t("usefull") }}</span
        >
      </div> -->
    </div>
  </div>
</template>
<script>
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { Detail } from "@/utils/gtm/";
import { formatSafari } from "@/utils/format";

import Feedback from "@/components/base/BaseComments/Feedback";
import { Carousel, Slide } from "vue3-carousel";
import Gallery from "@sutochno/gallery";
import BaseRating from "@/components/base/BaseRating";
import CommentRating from '@/components/base/BaseComments/CommentRating';
// TODO: hooper - обновить
import { useI18n } from 'vue-i18n';

export default {
  name: "Comment",
  components: {
    BaseRating,
    Carousel,
    Slide,
    Gallery,
    CommentRating,
    Feedback
  },
  props: {
    comment: {
      type: Object,
      default: null,
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      settings: {
        itemsToShow: 5,
        transition: 300,
        trimWhiteSpace: true,
      },
      viewFullText: false,
      height: null,
      viewImage: false,
      selectedSlide: 0,
      showAllDisadvantageText: false,
      showAllAdvantageText: false,
      benefit: "",
      maxCommentLength: 250,
    };
  },
  computed: {
    getCommentProperties() {
      return this.comment?.data?.properties;
    },
    roomTitle() {
      if (this.comment?.object?.id && this.isHotel) {
        return this.comment?.object?.title?.trim()
          ? this.comment.object.title
          : this.comment?.object?.title?.type_title;
      }
      return false;
    },
    getRatingForCommentRating() {
      if (this.getCommentProperties) {
        const { avg_rating, rating } = this.getCommentProperties;

        if(avg_rating) {
          return avg_rating.value;
        }

        return rating.value;
      }
    },
    guests() {
      let guests = 0;
      let childrenText = "";
      if (this.comment.cnt_man && this.comment.cnt_woman) {
        guests = Number(this.comment.cnt_man) + Number(this.comment.cnt_woman);
      } else if (this.comment.cnt_man) {
        guests = Number(this.comment.cnt_man);
      } else if (this.comment.cnt_woman) {
        guests = Number(this.comment.cnt_woman);
      }
      if (guests && this.comment.cnt_child) {
        const cnt_child = Number(this.comment.cnt_child);
        if (cnt_child === 1) {
          childrenText = this.t("child");
        } else if (cnt_child > 1) {
          childrenText = this.t("children");
        }
      }
      if (!guests && this.comment.cnt_guests) {
        guests = this.comment.cnt_guests;
      }

      return guests ? `${this.t("guests", guests)} ${childrenText}` : "";
    },
    getRatingText() {
      // Warning!!! расчет берется из 5 бальной системы и преобразует в 10 бальную
      const rating = Number(this.comment.data.properties?.rating?.value) * 2;
      if (rating > 10) {
        return "Error";
      }

      if (rating >= 0 && rating <= 2) {
        return this.t("ratingText.0");
      }
      if (rating > 2 && rating <= 4) {
        return this.t("ratingText.1");
      }
      if (rating > 4 && rating <= 6) {
        return this.t("ratingText.2");
      }
      if (rating > 6 && rating <= 7) {
        return this.t("ratingText.3");
      }
      if (rating > 7 && rating <= 9) {
        return this.t("ratingText.4");
      }
      if (rating > 9 && rating <= 10) {
        return this.t("ratingText.5");
      }
      return "";
    },
    username() {
      return this.comment?.user?.data?.properties?.first_name?.value;
    },
    dateStart() {
      const date = this.getCommentProperties?.rent_date_start?.value;
      if (date) {
        let dateArr = date.includes("-") ? date.split("-") : date.split(".");
        if (date.includes("-")) {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[0];
        } else {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[2];
        }
      }
      return false;
    },
    // плюсы
    advantageText() {
      if (this.comment?.status_id == 3 && this.getCommentProperties){
        const { new_pro, pro } = this.getCommentProperties;
        return this.getCommentText(new_pro?.value, pro?.value);
      }

      return "";
    },
    // замечания
    disadvantageText() {
      if (this.comment?.status_id == 3 && this.getCommentProperties){
        const { new_contra, contra } = this.getCommentProperties;
        return this.getCommentText(new_contra?.value, contra?.value);
      }

      return "";
    },
    isAllAdvantageText() {
      if (this.advantageText.length <= this.maxCommentLength || this.showAllAdvantageText) {
        return true;
      }
      return false;
    },
    showAdvantageText() {
      if (this.isAllAdvantageText) {
        return this.advantageText;
      }

      return this.advantageText.substring(0, this.maxCommentLength).trim() + "...";
    },
    isAllDisadvantageText() {
      if (this.disadvantageText.length <= this.maxCommentLength || this.showAllDisadvantageText) {
        return true;
      }
      return false;
    },
    showDisadvantageText() {
      if (this.isAllDisadvantageText) {
        return this.disadvantageText;
      }

      return this.disadvantageText.substring(0, this.maxCommentLength).trim() + "...";
    },
    getAddingTime() {
      if (isNaN(Date.parse(this.comment.date_add))) return false;
      const date_add = new Date(formatSafari(String(this.comment.date_add)));
      const addingDate = new Date(
        date_add.getFullYear(),
        date_add.getMonth(),
        date_add.getDate()
      );
      const date = new Date();
      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const passedDays = (currentDate - addingDate) / 86400000;
      if (passedDays < 1) return this.t("addingTime.0");
      else if (passedDays === 1) return this.t("addingTime.1");
      else if (passedDays === 2) return this.t("addingTime.2");
      else if (passedDays === 3) return this.t("addingTime.3");
      else if (passedDays === 4) return this.t("addingTime.4");
      else if (passedDays === 5) return this.t("addingTime.5");
      else if (passedDays === 6) return this.t("addingTime.6");
      else if (passedDays >= 7 && passedDays <= 13)
        return this.t("addingTime.7");
      else if (passedDays >= 14 && passedDays <= 20)
        return this.t("addingTime.8");
      else if (passedDays >= 21 && passedDays <= 27)
        return this.t("addingTime.9");
      else if (passedDays >= 28 && passedDays <= 38)
        return this.t("addingTime.10");
      else return this.dateComment(this.comment.date_add);
    },
    userAvatar() {
      const mediaUser = this.comment?.user?.data?.media?.[0]?.properties;
      if (!mediaUser) return undefined;
      return `//static.sutochno.ru${mediaUser.full_path.value}/small/${mediaUser.filename.value}`;
    },
  },
  mounted() {
    this.heightComment();
  },
  methods: {
    getCommentText(newText, text) {
      if (typeof newText == "string" && newText.trim()) {
        return newText;
      } else if (typeof text == "string" && text.trim()) {
        return text;
      }

      return "";
    },
    dateComment(dateAdd) {
      var date = new Date(formatSafari(String(dateAdd)));

      var formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formatter = formatter.format(date);
      return formatter;
    },
    heightComment() {
      if (
        this.$refs &&
        this.$refs[this.comment.id] &&
        this.$refs[this.comment.id].offsetHeight
      ) {
        this.$parent.commentsMore.push(
          this.$refs[this.comment.id].offsetHeight
        );
        this.height = this.$refs[this.comment.id].offsetHeight;
        if (this.isHotel && this.height >= 186) {
          return (this.viewFullText = false);
        } else {
          return (this.viewFullText = true);
        }
      } else {
        return false;
      }
    },
    selectSlide(index) {
      this.viewImage = !this.viewImage;
      this.selectedSlide = index;
    },
    clickUsefull() {
      this.benefit = "usefull";
      sendEventGtag("object_sa", {
        click: "review_plus",
        value: this.comment.id,
      });
    },
    clickUseless() {
      this.benefit = "useless";
      Detail.gaClickBenefit("desktop", "minus", this.comment.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.comments {
  &__item {
    display: flex;
    margin-bottom: 30px;
    position: relative;
  }
  &__more {
    position: absolute;
    height: 50px;
    // background: #444444;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.99) 40.32%,
      rgba(246, 245, 243, 0.0001) 100%
    );
    display: flex;
    align-items: flex-end;
    &-button {
      cursor: pointer;
      color: #2d6cb4;
      background: transparent;
      border: none;
      text-decoration: none;
      outline: none;
      padding: 0;
      &:hover {
        color: #f51449;
      }
    }
  }
  &__left {
    width: 40px;
    min-width: 40px;
  }
  &__img {
    display: block;
    height: 40px;
    width: 100%;
    background: #d8d8d8;
    border-radius: 50%;
    object-fit: cover;
  }
  &__img--default {
    display: block;
    height: 40px;
    width: 100%;
  }
  &__content {
    width: calc(100% - 55px);
    margin-left: 15px;
    flex-grow: 1;
    position: relative;
  }

  &__flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__booking-desc {
    display: flex;
    // align-items: center;
    color: #717171;
  }
  &__icon {
    margin-right: 10px;
    font-size: 16px;
  }
  &__days {
    margin-right: 20px;
  }
}
.comments__content .content {
  &__title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .review__owner {
      color: #717171;
      font-weight: normal;
    }
  }
  &__rating {
    margin-top: 5px;
  }
  &__category {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 10px 7px;
    border-radius: 3px;
    background-color: #f6f6f6;
    color: #717171;
  }
  &__comment {
    padding-top: 20px;
  }
  &__comment-advantage {
    position: relative;
  }
  &__comment-disadvantage {
    position: relative;
  }
  &__comment-advantage + .content__comment-disadvantage {
    margin-top: 15px;
  }
  &__comment-icon {
    position: absolute;
    left: -43px;
    top: 2px;
    font-size: 15px;
  }
  &__comment-like {
    color: #417505;
  }
  &__comment-dislike {
    color: #b90d0b;
  }
  &__comment-date {
    color: #717171;
  }
  &__comment-text {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
  }
  &__comment-more {
    margin-top: 5px;
    color: #2d6cb4;
    cursor: pointer;
  }
  &__commen-rating {
    line-height: 20px;
  }
  &__comment-slider {
    margin-top: 15px;
    .hooper {
      height: inherit;
      outline: none;
    }
    .img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 98px;
      border: 2px solid #fff;
      border-radius: 2px;
      cursor: grab;
      cursor: -moz-grab;
    }
  }
  &__comment-img-wrap {
    display: flex;
  }
  &__comment-img {
    width: 20%;
  }
  &__comment-benefit {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    color: #717171;
  }
  &__comment-useless {
    cursor: pointer;
    padding-right: 10px;
  }
  &__comment-usefull {
    cursor: pointer;
    padding-left: 10px;
  }
}
</style>
