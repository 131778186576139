<template>
  <ul class="rating-list" :class="{ dark: dark }">
    <li
      v-for="(rating, index) in dataRating"
      :key="index"
      class="icon-app-star"
    ></li>
  </ul>
</template>
<script>
export default {
  name: "BaseStars",
  props: {
    dataRating: {
      type: Number,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.rating-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  &.dark {
    li {
      color: #000;
    }
  }

  li {
    // Pixel Perfect
    margin-left: -2px;
    margin-right: 6px;

    text-align: center;
    font-size: 16px;
    width: 12px;
    color: #717171;
  }
}
</style>
