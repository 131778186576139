<i18n lang="json">
{
  "ru": {
    "toSearch": "К поиску"
  },
  "en": {
    "toSearch": "To search"
  }
}
</i18n>
<template>
  <div>
    <ul class="path" :class="typeObject">
      <li v-if="city">
        <div 
          class="path__back"
          @click="toSearch"
        >
          {{ t("toSearch") }}
        </div>
      </li>
      <li v-if="country && countrySelected !== -1 && !isDisputedRegion">
        <ListCity
          :city="country"
          :selected="countrySelected"
          type-location="country"
          :type-object="typeObject"
        />
      </li>
      <li v-if="region && countrySelected !== -1 && !isDisputedRegion">
        <ListCity
          :city="region"
          :selected="regionSelected"
          type-location="region"
          :type-object="typeObject"
        />
      </li>
      <li v-if="city && countrySelected !== -1">
        <ListCity
          :city="city"
          :selected="citySelected"
          type-location="city"
          :type-object="typeObject"
        />
      </li>
      <li>
        <span :title="`№ ${idObject}`">{{ `№ ${idObject}` }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";

import { HTTP, Search } from "@/components/api/";
import ListCity from "./ListCity";
import { useI18n } from 'vue-i18n';

export default {
  name: "BasePath",
  components: {
    ListCity,
  },
  props: {
    typeObject: {
      type: String,
      default: "",
    },
    idObject: {
      type: Number,
    },
    locationObject: {
      type: Object
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      citySuggestions: "",
      searchPageLoaded: true,
    };
  },
  mounted() {
    if (this.idObject) {
      this.init();
    }
  },
  computed: {
    ...mapState("search", ["location", "bound"]),
    ...mapState("detail", ["allLocations", "locationsCntObjectsLoad"]),
    ...mapGetters("search", ["getQuery"]),
    country(){
      return this.allLocations.country;
    },
    region(){
      return this.allLocations.region;
    },
    city(){
      return this.allLocations.city;
    },
    countrySelected(){
      return this.allLocations.countrySelected;
    },
    regionSelected(){
      return this.allLocations.regionSelected;
    },
    citySelected(){
      return this.allLocations.citySelected;
    },
    isDisputedRegion(){
      const disputedRegionsId = [19916, 19905, 19931, 19908]
      const region = this.region[this.regionSelected];
      const { id } = region || {};
      return disputedRegionsId.includes(id);
    }
  },
  methods: {
    ...mapMutations("detail", ["setLocationObject"]),
    ...mapActions("detail", ["getLocationsCntObjects"]),
    init() {
      let formData = new FormData();
      formData.append(`filter[${this.typeObject}]`, this.idObject);

      if(!this.locationsCntObjectsLoad){
        this.getLocationsCntObjects(formData);
      }
    },
    getTermSuggestions(request) {
      return Search.getTermSuggestionsWithBoundingBox(request)
        .then((response) => {
          this.citySuggestions = response.data.data.suggestions;
          return response
        })
        .catch((error) => {
          console.warn("Ajax error:", error);
        });
    },
    async toSearch() {
      if (this.searchPageLoaded) {
        this.searchPageLoaded = false;

        await this.getTermSuggestions(this.locationObject.location);

        let {
          location,
          bbox,
          title,
        } = this.citySuggestions[0] || {};

        const {
          id,
          type
        } = this.getQuery.query;

        this.$store.dispatch("search/setLocation", {
          id: location?.id,
          type: location?.type,
          bbox: id == 1 && type == 'country' ? bbox : this.bound,
          term: title
        })

        await this.$nextTick(() => {})

        sendEventGtag("object_sa_m", {
          click: "back2search",
        });
        setTimeout(() => {
          this.$router.push({
            name: 'Search',
            query: _.omit({...this.getQuery.query, objectScroll: this.idObject}, ["lat", "lng", "zoom"])
          });
        }, 300);

        this.searchPageLoaded = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.path {
  padding: 0;
  margin: 0 0 8px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;

  &__back {
    cursor: pointer;
  }

  > li {
    position: relative;
    color: #444444;
    margin-right: 10px;
    &:first-child {
      margin-right: 36px;
    }
    a {
      cursor: pointer;
      color: #444444;
      text-decoration: none;
      &:hover {
        color: #444444;
      }
    }
  }
  &.hotel_id {
    > li {
      color: #717171;
      a {
        cursor: pointer;
        color: #717171;
        &:hover {
          color: #717171;
        }
      }
    }
  }
}
</style>
