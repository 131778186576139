<i18n lang="json">
{
	"ru": {
        "showSuggestions": "показывать предложения рядом",
        "wolkTime": "менее минуты | {n} минута | {n} минуты | {n} минут",
        "meter": "м",
        "kmeter": "км",
        "hintPlace": "смотреть все варианты",
        "distance": "{n} м",
        "route": "маршрут",
        "foot": "пешком",
        "on": "на",
        "byCar": "авто",
        "hint_sea": "Указано прямое расстояние до моря. Реальное расстояние в пути может быть больше.",
        "distance_meters": "{distance} м {to}",
        "distance_kilometers": "{distance} км {to}",
        "onFootByCar": "на авто",
        "district": "р-н"
	},
	"en": {
        "showSuggestions": "show suggestions nearby",
        "wolkTime": "less than a minute | {n} minute | {n} minutes | {n} minutes ",
        "meter": "m",
        "kmeter": "km",
        "hintPlace": "смотреть все варианты",
        "distance": "{n} m",
        "route": "route",
        "foot": "on foot",
        "on": "on",
        "byCar": "auto",
        "hint_sea": "Showed direct distance calculated automatically; real distance to the sea may be longer.",
        "distance_meters": "{distance} m {to}",
        "distance_kilometers": "{distance} km {to}",
        "onFootByCar": "by car",
        "district": "district"
	}
}
</i18n>
<template>
  <div class="map-info">
    <div class="map-info--address tmp-font--big_bold">
      {{ location.address }}
    </div>
    <div class="map-info--top tmp-font--medium">
      <div class="address">
        <template v-if="location">
          <p
            v-if="location.location"
            class="property-size hint-popup text-center"
            :data-hint="`${location.location} — ${t('hintPlace')}`"
          >
            <router-link
              class="text-small info-item-pad-xl"
              :to="{
                name: 'Search',
                query: {
                  id: $route.query.id,
                  type: $route.query.type,
                  term: location.location,
                },
              }"
              >{{ location.location }}</router-link
            >
          </p>
          <p
            v-if="location && location.place && location.place.title"
            class="property-size hint-popup text-center"
          >
            <span class="text-small info-item-pad-xl"
              >, {{ location.place.title }} {{ t('district') }}</span
            >
          </p>
        </template>

        <div class="sea" v-if="sea">
          <div class="sea__icon">
            <span class="icon-app-sea"></span>
          </div>
          <div class="sea__text">
            <span
              class="cursor-default"
              v-tooltip="{
                content: t('hint_sea'),
                popperClass: 'v-popper__popper--max-width-300',
              }"
            >
              {{ sea }}
            </span>
          </div>
        </div>
      </div>
      <div class="switcher">
        <Switcher v-model="isResults" :text="t('showSuggestions')" />
      </div>
    </div>
    <div class="map-info--map">
      <MapLayout
        :results="resultsFilter"
        :isResults="isResults"
        :ropeway="ropeway"
        :isShowMetro="isShowMetro"
      />
    </div>
  </div>
</template>
<script>
import MapLayout from "@/components/mobile/Detail/Map/MapLayout";
import { mapState, mapGetters } from "vuex";
import seas from "@/assets/json/seas.json";
import Switcher from "@sutochno/switcher";
import { HTTP } from "@/components/api/index.js";
import { useI18n } from 'vue-i18n';
import axios from 'axios';

export default {
  name: "MapInfo",
  components: {
    MapLayout,
    Switcher,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    ropeway: {
      type: Array,
      required: false,
    },
    isShowMetro: {
      type: Boolean,
      required: false,
    },
    object: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isResults: false,
      results: [],
      status: {
        1: "loading",
      },
      perPage: 20,
      cancelSource: null,
      adblock: 0, // Рекламная позиция 0 = выкл
    };
  },
  watch: {
    isResults(val) {
      if (val && this.results.length === 0) {
        this.getObjects();
      }
    },
  },
  computed: {
    ...mapState("search", { storeLocation: "location" }),
    ...mapState("detail", ["locationObject"]),
    ...mapState("user", ["currency"]),
    ...mapState("search", ["bound"]),
    ...mapGetters("search", ["occupied", "days"]),
    resultsFilter() {
      if (this.results[1])
        return this.results[1].filter((item) => item.id != this.object.id);
      else return [];
    },
    sea() {
      if (!(this?.location?.seas && this?.location?.seas[0])) return;

      const distance = this?.location?.seas[0]?.distance;
      const to = seas[this?.location?.seas[0]?.sea]?.to
        ? seas[this.location.seas[0].sea].to
        : "";

      if (distance < 50) {
        return this.t("distance_meters", {
          distance: "< 50",
          to,
        });
      } else if (distance < 991) {
        return this.t("distance_meters", {
          distance: (Math.ceil(distance / 10) * 10).toLocaleString(),
          to,
        });
      } else {
        return this.t("distance_kilometers", {
          distance: (
            (Math.round(distance / 100) * 100) /
            1000
          ).toLocaleString(),
          to,
        });
      }
    },
  },
  methods: {
    initMapInfo() {
      if (this.isShowMetro) {
        this.getDirections(this.location, this.metro[0]);
        this.activeMapPoint = 0;
      }
    },
    async getObjects(pageNum = 1) {
      const [dateBegin, dateEnd] = this.occupied ? this.occupied.split(';') : [null, null];
      this.status[pageNum] = "loading";
      let request = {
        currency_id: this.currency.id,
        "NE[lat]": this.bound ? this.bound.NE.lat : "",
        "NE[lng]": this.bound ? this.bound.NE.lng : "",
        "SW[lat]": this.bound ? this.bound.SW.lat : "",
        "SW[lng]": this.bound ? this.bound.SW.lng : "",
        // "size[x]": 865,
        // "size[y]": 421,
        zoom: 13,
        count: this.perPage,
        offset: (pageNum - 1) * this.perPage,
      };
      if (dateBegin && dateEnd) {
        request.dateBegin = dateBegin;
        request.dateEnd = dateEnd;
      }
      if (this.cancelSource && pageNum == 1) {
        this.cancelSource.cancel("Operation canceled by the user.");
      }
      this.cancelSource = axios.CancelToken.source();
      HTTP.get("json/search/searchObjects", {
        headers: {
          "api-version": String(null),
        },
        params: request,
        cancelToken: this.cancelSource.token,
        cache: false,
      })
        .then((response) => ({
          result: response.data.data.objects,
          adblock: Number(
            response.data.data.ad_block ||
              0 || 
              JSON.parse(localStorage.getItem("VUE_APP_ADBLOCK") || "0")
          ),
          count: response.data.data.total_count || response.data.data.count,
        }))
        .then(({ result, count, adblock }) => {
          this.$store.commit("search/setTotalFound", count);
          if (pageNum == 1) {
            this.results = {};
            this.status = {
              1: "loading",
            };
          }
          result.forEach((elem, index) => {
            elem.price.full = Math.round(elem.price.full) || null;
            elem.price.per_night = Math.round(elem.price.per_night) || null;

            const countDays = this.days ? this.days : 1;
            elem.price.average_per_night = Math.round(elem.price.per_night / countDays);
            elem.price.full_per_night = Math.round(elem.price.without_discounts / countDays);
          });
          this.adblock = adblock;
          this.results[pageNum] = result;

          if (count == 0 || count == this.results.length) {
            this.status[pageNum] = "complete"
          } else {
            this.status[pageNum] = "loaded"
          }
          this.$store.commit("search/setCardVisible", false);
          this.$store.commit("search/setSelectedMapPointId", null);
        })
        .catch((error) => {
          this.status[pageNum] = "fail";
          console.warn("Ошибка при загруки выдачи:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-info {
  color: #000;

  &--address {
    margin-bottom: 14px;
  }

  &--top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    .address {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        align-items: flex-start;
        flex-direction: column;
      }
      p {
        margin: 0;
      }
      .sea {
        margin-left: 15px;
        display: flex;
        align-items: center;
        &__icon {
          .icon-app-sea {
            color: #666;
            font-size: 16px;
          }
        }
        &__text {
          font-size: 14px;
          color: #000;
          margin-left: 10px;
        }
      }
    }
    .switcher {
      :deep(.sc-switcher-body) {
        padding-right: 20px;
      }
    }
  }

  &--map {
    margin-bottom: 10px;
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    //margin-bottom: 14px;

    .map-point {
      display: flex;
      flex: 1 1 auto;
      width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      align-items: center;
      position: relative;
      border-radius: 21px;
      padding: 4px 0;
      margin-top: 0;
      margin-bottom: 0;
      background-color: #fff;
      overflow: hidden;
      transition: all 0.3s ease;

      &:nth-child(odd) {
        margin-right: 10px;
      }

      &:nth-child(even) {
        margin-left: 10px;
      }

      &.ropeway {
        width: 100%;
        max-width: 100%;
        &:nth-child(odd) {
          margin-right: 0;
        }

        &:nth-child(even) {
          margin-left: 0;
        }
        .icon-elevator {
          display: inline-block;
          margin-right: 10px;
          width: 14px;
          height: 14px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuOTk2OTUgMTAuNzcxNEM0Ljk5Njk1IDExLjU4NzkgNS42NTg4NyAxMi4yNDk4IDYuNDc1MzUgMTIuMjQ5OEg5LjY0NTkzQzEwLjQ2MjQgMTIuMjQ5OCAxMS4xMjQzIDExLjU4NzkgMTEuMTI0MyAxMC43NzE0VjEwLjA2MjVINC45OTY5NUw0Ljk5Njk1IDEwLjc3MTRaIiBmaWxsPSIjNDE3NTA1Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAwVjAuOTY2MTRMOC40OTgxNCAzLjU0NTE1VjYuMTI1SDkuODEwNjRDMTEuNDk5MyA2LjEyNSAxMi44NzMxIDcuNDk5MDMgMTIuODczMSA5LjE4NzVWMTAuOTM3NUMxMi44NzMxIDEyLjYyNiAxMS40OTkzIDE0IDkuODEwNjQgMTRINi4zMTA2NEM0LjYyMTk2IDE0IDMuMjQ4MTQgMTIuNjI2IDMuMjQ4MTQgMTAuOTM3NVY5LjE4NzVDMy4yNDgxNCA3LjQ5OTAzIDQuNjIxOTYgNi4xMjUgNi4zMTA2NCA2LjEyNUg3LjYyMzE0VjMuOTU1MjhMMCA3LjUyODY0VjYuNTYyNUwxNCAwWk0xMS45OTgxIDEwLjkzNzVWOS4xODc1QzExLjk5ODEgNy45ODE0IDExLjAxNjcgNyA5LjgxMDY0IDdINi4zMTA2NEM1LjEwNDU0IDcgNC4xMjMxNCA3Ljk4MTQgNC4xMjMxNCA5LjE4NzVWMTAuOTM3NUM0LjEyMzE0IDEyLjE0MzYgNS4xMDQ1NCAxMy4xMjUgNi4zMTA2NCAxMy4xMjVIOS44MTA2NEMxMS4wMTY3IDEzLjEyNSAxMS45OTgxIDEyLjE0MzYgMTEuOTk4MSAxMC45Mzc1WiIgZmlsbD0iIzQxNzUwNSIvPjwvc3ZnPg==);
          background-position: center;
          filter: grayscale(1);
        }
      }

      &.active {
        padding: 8px 0 8px 15px;
        margin-top: 3px;
        margin-bottom: 3px;
        background-color: #f6f6f6;

        .map-point--close {
          right: 10px;
        }
        .map-point--name {
          &.long {
            width: 100%;
            max-width: 200px;
            @media only screen and (max-width: 1280px) {
              max-width: 130px;
            }
            @media only screen and (max-width: 1024px) {
              max-width: 90px;
            }
          }
          @media only screen and (max-width: 1280px) {
            max-width: 130px;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 90px;
          }
        }
      }

      &--color {
        width: 10px;
        height: 10px;
        min-width: 10px;
        border-radius: 20px;
        margin-right: 10px;
      }

      &--name {
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--time {
        cursor: pointer;
        color: #2d6cb4;
        margin-right: 5px;
        white-space: nowrap;
        .icon-app-walk {
          color: #444;
          font-size: 16px;
          vertical-align: bottom;
          margin-left: 3px;
        }
      }
      &--foot-on-bycar {
        cursor: pointer;
        color: #2d6cb4;
        margin-left: 16px;
        .icon-app-car-map {
          color: #444;
          font-size: 16px;
          vertical-align: bottom;
          margin-left: 3px;
        }
      }

      &--icon {
        .icon-app-walk {
          color: #444;
          font-size: 16px;
          vertical-align: bottom;
        }
      }

      &--close {
        cursor: pointer;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: -24px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        .icon-app-close-btn {
          color: #444444;
          font-size: 15px;
          vertical-align: bottom;
        }
      }
    }
  }
  &--padding-bot {
    height: 14px;
    transition: height 0.3s ease;
    &.active {
      height: 0;
    }
  }
  &--padding-top {
    height: 14px;
    transition: height 0.3s ease;
    &.active {
      height: 0;
    }
  }

  &--bot {
    display: flex;
    flex-wrap: wrap;

    .nearest-points {
      flex: 1 1 auto;
      width: 50%;
      max-width: 50%;

      &--title {
        margin-bottom: 10px;
      }
    }
  }

  .ropeway-title {
    margin-bottom: 10px;
  }
}
.ropeway-more {
  padding-top: 12px;
}
</style>
